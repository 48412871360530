import React, { useState } from 'react';
import DataSetType from 'types/dataset.type';
import Dialog, { ContentWithSidebarWrapper } from 'components/ui-components/Dialog';
import { SidebarItem } from 'components/ui-components/Dialog/components/sidebar-items';
import { FeedFormDialog } from 'components/feed-management/FeedForm';
import FeedTable from 'components/feed-management/FeedTable';
import Translation from 'components/data/Translation';
import { BrickFeedData } from 'components/bricks/types/brick.type';
import '../styles/main.scss';

const sideBarItems: SidebarItem[] = [
    {
        type: 'selectFeed',
        description: Translation.get('addFeedDialog.selectExistingFeed', 'feed-management'),
        icon: 'rss_feed',
        title: Translation.get('addFeedDialog.selectExistingFeed', 'feed-management')
    },
    {
        type: 'createFeed',
        description: Translation.get('addFeedDialog.createNewFeed', 'feed-management'),
        icon: 'add_circle_outline',
        title: Translation.get('addFeedDialog.createNewFeed', 'feed-management')
    }
];

interface Props {
    onSelectFeed: (brickFeedData: BrickFeedData) => void;
    onClose: () => void;
    createFeed?: boolean;
}

const AddFeedDialog = ({ onSelectFeed, onClose, createFeed = true }: Props) => {
    const [dialogType, setDialogType] = useState<string>(sideBarItems[0].type);

    const handleSelectFeed = (datasetId: string, feedId: string) => {
        onSelectFeed({ datasetId, feedId });
        onClose();
    };

    const newSideBarItems = (() => {
        if (createFeed) {
            return sideBarItems;
        }

        return sideBarItems.filter((item) => item.type !== 'createFeed');
    })();

    return (
        <Dialog open title={Translation.get('addFeedDialog.addFeed', 'feed-management')} showCancel confirmButtonDisabled={false} onClose={onClose} fixedHeight>
            <ContentWithSidebarWrapper
                classes={{
                    content: 'add-feed-dialog__content'
                }}
                sidebarItems={newSideBarItems}
                defaultActiveSidebarItem="selectFeed"
                onChangeActiveSidebarItem={(type: string) => setDialogType(type)}>
                <div className="add-feed-dialog">
                    {dialogType === 'selectFeed' && <FeedTable onSelectFeed={handleSelectFeed}></FeedTable>}

                    {dialogType === 'createFeed' && (
                        <FeedFormDialog
                            inCampaign={true}
                            callback={(dataset: DataSetType) => handleSelectFeed(dataset._id, dataset.feeds[0].feedId)}
                            onClose={() => setDialogType('selectFeed')}
                            view={'form-flow'}
                            showSaveNotification={false}
                        />
                    )}
                </div>
            </ContentWithSidebarWrapper>
        </Dialog>
    );
};

export default AddFeedDialog;
